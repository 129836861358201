import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import {
  Text,
  TextField,
  PrimaryButton,
  Spinner,
  MessageBar,
  MessageBarType,
  Checkbox,
  Link,
} from 'office-ui-fabric-react/lib';
import { PropTypes } from 'prop-types';
import Header from '../../components/Header';
import Stack from '../../components/Stack';
import { traerJugadorPorEmail } from '../../utils/equipos';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      currentError: {},
    };
  }

  onChange = event => {
    const { currentUser } = this.state;
    const { value, id } = event.target;
    currentUser[id] = value;
    this.setState({ currentUser });
  };

  activate = () => {
    const { currentUser } = this.state;
    if (!currentUser.terms) {
      this.setState({ error: 'Debes aceptar términos y condiciones' });
      return;
    }
    if (!currentUser.email) {
      this.setState({ error: 'e-mail inválido' });
      return;
    }
    if (!currentUser.password) {
      this.setState({ error: 'Contraseña inválida' });
      return;
    }
    if (currentUser.password !== currentUser.password2) {
      this.setState({ error: 'Contraseñas no concuerdan' });
      return;
    }
    this.setState({ loading: true });
    this.validateEmail();
  };

  validateEmail = async () => {
    const {
      currentUser: { email },
    } = this.state;
    const { firebase } = this.props;
    try {
      const ref = await firebase.ref('admons');
      const snapshot = await ref
        .orderByChild('email')
        .equalTo(email)
        .once('value');
      const result = snapshot.val();
      if (result) {
        const { role = 'admon' } = Object.values(result)[0];
        this.registrar({ role });
      } else {
        this.validateCaptain();
      }
    } catch (error) {
      const { message } = error;
      this.setState({ error: message, loading: false });
    }
  };

  validateCaptain = async () => {
    const {
      currentUser: { email },
    } = this.state;
    try {
      const result = await traerJugadorPorEmail(email);
      if (result) {
        this.registrar({ capitan: true });
      } else {
        throw new Error('El correo no registra en nuestra base de datos');
      }
    } catch (error) {
      const { message } = error;
      this.setState({ error: message, loading: false });
    }
  };

  registrar = async params => {
    const {
      currentUser: { email, password },
    } = this.state;

    const { firebase } = this.props;
    try {
      await firebase.createUser({
        email,
        password,
        ...params,
      });
      this.updateParams(params);
    } catch (error) {
      const { message } = error;
      this.setState({ error: message, loading: false });
    }
  };

  updateParams = async params => {
    try {
      const { firebase } = this.props;
      await firebase.update(`users/${firebase.auth().currentUser.uid}`, params);
      window.location.reload();
    } catch (error) {
      const { message } = error;
      this.setState({ error: message, loading: false });
    }
  };

  navigate = path => {
    const { history } = this.props;
    history.push(path);
  };

  cleanError = () => {
    this.setState({ error: null });
  };

  render() {
    const { currentUser, currentError, loading, error } = this.state;
    return (
      <>
        <Header />
        <Stack column className="formContainer">
          <Text variant="xLarge">Activar cuenta</Text>
          {error && (
            <MessageBar
              messageBarType={MessageBarType.error}
              onDismiss={this.cleanError}>
              {error}
            </MessageBar>
          )}
          <br />
          <TextField
            label="e-mail"
            required
            onChange={this.onChange}
            id="email"
            value={currentUser.email}
            errorMessage={currentError.email}
            type="email"
          />
          <TextField
            label="contraseña"
            required
            onChange={this.onChange}
            id="password"
            value={currentUser.password}
            errorMessage={currentError.password}
            type="password"
          />
          <TextField
            label="confirmar contraseña"
            required
            onChange={this.onChange}
            id="password2"
            value={currentUser.password2}
            errorMessage={currentError.password2}
            type="password"
          />
          <br />
          <Stack>
            <Checkbox
              label="Acepto términos y condiciones."
              isRequired
              onChange={value =>
                this.onChange({
                  target: { value: value.target.checked, id: 'terms' },
                })
              }
              checked={currentUser.terms || false}
            />
            <Link
              href="https://torneosoccercup.com/terminosycondicionesdatos/"
              target="_blank">
              &nbsp;(Ver aquí)
            </Link>
          </Stack>
          <br />
          <PrimaryButton onClick={this.activate} disabled={loading}>
            Activar cuenta
          </PrimaryButton>
          <br />
          {loading && <Spinner />}
          <br />
        </Stack>
      </>
    );
  }
}

Registration.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  firebase: PropTypes.shape({}).isRequired,
};

export default withFirebase(Registration);
