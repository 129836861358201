import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Text,
  DefaultButton,
  PrimaryButton,
  TextField,
  DialogFooter,
  Modal,
  Spinner,
} from 'office-ui-fabric-react/lib';
import { withFirebase } from 'react-redux-firebase';

import Stack from '../Stack';
import { validateTeam, modelTeam } from './Forms/Team';

class TeamForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isNew: true,
      currentTeam: { ...modelTeam },
      currentError: {},
    };
  }

  componentDidUpdate = prevProps => {
    const { showModal, team } = this.props;
    if (!prevProps.showModal && showModal) {
      this.setState({
        currentTeam: { ...modelTeam, ...team },
        loading: false,
        isNew: Object.keys(team).length === 0,
      });
    }
  };

  save = async () => {
    const { currentTeam, isNew } = this.state;
    this.setState({ loading: true });
    const currentError = await validateTeam(currentTeam);

    this.setState({ currentError });
    if (Object.keys(currentError).length > 0) {
      this.setState({ loading: false });
      return;
    }
    const { onHideModal, firebase } = this.props;

    if (isNew) {
      await firebase.push('teams', currentTeam);
    } else {
      const { tid, ...team } = currentTeam;
      await firebase.update(`teams/${tid}`, team);
    }
    onHideModal();
  };

  onChange = event => {
    const { currentTeam } = this.state;
    const { value, id } = event.target;
    currentTeam[id] = value.substring(0, 20).toUpperCase();
    this.setState({ currentTeam });
  };

  render() {
    const { loading, currentError, currentTeam, isNew } = this.state;
    const { showModal, onHideModal } = this.props;
    return (
      <Modal isOpen={showModal} onDismiss={!loading ? onHideModal : null}>
        <div className="modalContent">
          <Stack middle between>
            <Text variant="xLarge">{isNew ? 'Agregar' : 'Editar'} equipo</Text>
            {loading && <Spinner />}
          </Stack>
          <div className="formModal">
            <TextField
              label="Nombre del equipo"
              required
              value={currentTeam.name.toUpperCase()}
              errorMessage={currentError.name}
              onChange={this.onChange}
              id="name"
            />
          </div>

          <DialogFooter>
            <PrimaryButton
              onClick={this.save}
              text={isNew ? 'Agregar' : 'Editar'}
              disabled={loading}
            />
            <DefaultButton
              onClick={onHideModal}
              text="Cancelar"
              disabled={loading}
            />
          </DialogFooter>
        </div>
      </Modal>
    );
  }
}

TeamForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  team: PropTypes.shape({ name: PropTypes.string }),
  firebase: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

TeamForm.defaultProps = {
  team: {},
};

export default withFirebase(TeamForm);
