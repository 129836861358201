import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import Loading from '../components/Loading';

const locationHelper = locationHelperBuilder({});

export const UserIsAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAuthenticated',
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && !auth.isEmpty,
  redirectAction: () => dispatch => {
    dispatch({ type: 'UNAUTHED_REDIRECT' });
  },
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsNotAuthenticated',
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/teams',
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty,
  redirectAction: () => dispatch => {
    dispatch({ type: 'UNAUTHED_REDIRECT' });
  },
});

const defaultValues = {};

export const profileStateToParams = ({
  firebase: {
    profile,
    auth,
    data: { roles, teams, jugadores },
  },
}) => ({
  profile: {
    idLogged: auth.isLoaded && !auth.isEmpty,
    ...defaultValues,
    ...profile,
    isAdmin: profile.role === 'admon',
    isViewer: profile.role === 'viewer',
    uid: auth.uid,
    ...roles,
  },
  teams,
  jugadores,
});
export const profileOnly = compose(
  withFirebase,
  connect(profileStateToParams),
);
