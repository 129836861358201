import React from 'react';
import { PropTypes } from 'prop-types';
import Stack from '../Stack';

const CommandHeader = ({ children, title }) => {
  return (
    <div className="commandHeader">
      <h4>{title}</h4>
      <Stack between>{children}</Stack>
    </div>
  );
};

CommandHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default CommandHeader;
