import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from 'react-redux-firebase';
import firebase from 'firebase/app';

import Navigation from './navigation';
import * as serviceWorker from './serviceWorker';
import './index.css';
import Config from './config.json';

initializeIcons();

const rrfConfig = {
  userProfile: 'users',
  profileParamsToPopulate: [{ child: 'role', root: 'roles' }],
  profileFactory: ({ user }) => {
    return {
      email: user.email || user.providerData[0].email,
      role: 'user',
    };
  },
};

firebase.initializeApp(Config.firebase);

const rootReducer = combineReducers({
  firebase: firebaseReducer,
});

const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const App = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Navigation />
    </ReactReduxFirebaseProvider>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
