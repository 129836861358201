import React from 'react';
import { Spinner } from 'office-ui-fabric-react';
import Stack from '../Stack';

const Loading = () => {
  return (
    <Stack middle column>
      <br />
      <Spinner />
    </Stack>
  );
};

export default Loading;
