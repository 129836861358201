import { database } from 'firebase';

export const editarJugador = async ({ oldId, ...jugador }, team) => {
  await database()
    .ref(`jugadores/${team.tid}/${jugador.identification}`)
    .update({ ...jugador, updatedOn: new Date() });

  if (jugador.identification !== oldId) {
    await database()
      .ref(`jugadores/${team.tid}/${oldId}`)
      .set(null);
  }
};

export const deleteTeam = async tid => {
  await database()
    .ref(`teams/${tid}`)
    .set(null);

  await database()
    .ref(`jugadores/${tid}`)
    .set(null);
};

export const hacerCambio = async (team, cambios) => {
  await database()
    .ref(`teams/${team.tid}`)
    .update({ cambios: cambios + 1 });
};

export const traerTeamJugador = async jugador => {
  const ref = await database().ref('jugadores');
  const snapshot = await ref.once('value');
  const result = snapshot.val() || {};

  return Object.keys(result).find(key => {
    return result[key][jugador.identification];
  });
};

export const traerJugadorPorEmail = async email => {
  const ref = await database().ref('jugadores');
  const snapshot = await ref.once('value');
  const result = snapshot.val() || {};

  let user = null;
  Object.values(result).find(player => {
    return Object.values(player).find(item => {
      const isCreated =
        (item.email || '').toLowerCase() === email.toLowerCase();
      if (isCreated) {
        user = item;
      }
      return isCreated;
    });
  });
  return user;
};

export const traerEquiposJugador = async user => {
  const ref = await database().ref('jugadores');
  const snapshot = await ref.once('value');
  const result = snapshot.val() || {};

  return Object.keys(result).filter(key => {
    return Object.values(result[key]).find(item => {
      return (
        user.email.toLowerCase() === (item.email || '').toLowerCase() &&
        item.capitan
      );
    });
  });
};

export const traerJugadores = async team => {
  const ref = await database().ref(`jugadores/${team.tid}`);
  const snapshot = await ref.once('value');
  return Object.values(snapshot.val() || {});
};
