import { validateForm } from '../../../utils/validator';

export const modelTeam = {
  name: '',
};

export const validateTeam = async team => {
  const schema = {
    name: {
      type: 'string',
      empty: false,
      messages: { stringEmpty: 'Ingresa un nombre' },
    },
  };

  const currentError = validateForm(team, schema);

  return currentError;
};
