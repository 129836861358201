import React from 'react';
import { PropTypes } from 'prop-types';

const Stack = ({ children, between, middle, className, column, end }) => {
  return (
    <div
      className={`stack ${between && 'stackBetween'} ${middle &&
        'stackMiddle'} ${column && 'stackColumn'} ${end &&
        'stackEnd'} ${className}`}>
      {children}
    </div>
  );
};

Stack.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  between: PropTypes.bool,
  middle: PropTypes.bool,
  column: PropTypes.bool,
  end: PropTypes.bool,
  className: PropTypes.string,
};

Stack.defaultProps = {
  between: false,
  middle: false,
  column: false,
  end: false,
  className: '',
};

export default Stack;
