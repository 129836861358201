import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../views/Login';
import Registration from '../views/Registration';
import RemindPassword from '../views/RemindPassword';
import Teams from '../views/Teams';
import View404 from '../views/View404';
import { UserIsNotAuthenticated, UserIsAuthenticated } from '../utils/enhacers';

const App = () => {
  return (
    <BrowserRouter>
      <Route
        render={({ location }) => {
          return (
            <Switch location={location}>
              <Route exact path="/" component={UserIsNotAuthenticated(Login)} />
              <Route
                exact
                path="/teams/:teamId?"
                component={UserIsAuthenticated(Teams)}
              />
              <Route
                exact
                path="/registration"
                component={UserIsNotAuthenticated(Registration)}
              />
              <Route
                exact
                path="/remind"
                component={UserIsNotAuthenticated(RemindPassword)}
              />

              <Route exact component={View404} />
            </Switch>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default App;
