import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import {
  Text,
  TextField,
  PrimaryButton,
  ActionButton,
  Spinner,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react/lib';
import { PropTypes } from 'prop-types';
import Header from '../../components/Header';
import Stack from '../../components/Stack';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      currentError: {},
      error: null,
    };
  }

  onChange = event => {
    const { currentUser } = this.state;
    const { value, id } = event.target;
    currentUser[id] = value;
    this.setState({ currentUser });
  };

  login = async () => {
    const {
      currentUser: { email, password },
    } = this.state;
    const { firebase } = this.props;
    this.setState({ loading: true, error: null });
    try {
      await firebase.login({
        email,
        password,
      });
      this.navigate('/teams');
    } catch (error) {
      const { message } = error;
      this.setState({ error: message, loading: false });
    }
  };

  cleanError = () => {
    this.setState({ error: null });
  };

  navigate = path => {
    const { history } = this.props;
    history.push(path);
  };

  render() {
    const { currentUser, currentError, loading, error } = this.state;
    return (
      <>
        <Header />
        <Stack column className="formContainer">
          <Text variant="xLarge">Inicio de sesión</Text>
          {error && (
            <MessageBar
              messageBarType={MessageBarType.error}
              onDismiss={this.cleanError}>
              {error}
            </MessageBar>
          )}
          <br />
          <TextField
            label="e-mail"
            required
            onChange={this.onChange}
            id="email"
            value={currentUser.email}
            errorMessage={currentError.email}
            type="email"
          />
          <TextField
            label="contraseña"
            required
            onChange={this.onChange}
            id="password"
            value={currentUser.password}
            errorMessage={currentError.password}
            type="password"
          />
          <br />
          <br />
          <PrimaryButton onClick={this.login} disabled={loading}>
            Iniciar sesión
          </PrimaryButton>
          <br />
          {loading && <Spinner />}
          <br />
          <br />
          <Stack>
            <ActionButton
              iconProps={{ iconName: 'AddFriend' }}
              onClick={() => this.navigate('/registration')}>
              Activar cuenta
            </ActionButton>
            <ActionButton
              iconProps={{ iconName: 'FollowUser' }}
              onClick={() => this.navigate('/remind')}>
              Recordar contraseña
            </ActionButton>
          </Stack>
        </Stack>
      </>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  firebase: PropTypes.shape({}).isRequired,
};

export default withFirebase(Login);
