import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Shimmer,
  ShimmeredDetailsList,
  MarqueeSelection,
  ProgressIndicator,
  Modal,
  DefaultButton,
  PrimaryButton,
  DetailsListLayoutMode,
  Selection,
  TextField,
  MaskedTextField,
  DialogFooter,
  DatePicker,
  DayOfWeek,
  Text,
  Checkbox,
  ComboBox,
  Dialog,
} from 'office-ui-fabric-react/lib';
import { CSVLink } from 'react-csv';
import { subYears } from 'date-fns';
import CommandHeader from '../CommandHeader';
import Stack from '../Stack';
import {
  traerJugadores,
  editarJugador,
  hacerCambio,
  deleteTeam,
} from '../../utils/equipos';
import validator from '../../utils/validator';
import jugadoresForm, { validarUsuario, modelUser } from './Forms/Jugadores';
import TeamForm from '../TeamForm';
import { profilePropType } from '../../utils/proptypes';
import { profileOnly } from '../../utils/enhacers';
import { formatShortDate, formatDate } from '../../utils/dates';
import {
  LIMITE_JUGADORES,
  CAMBIOS_ETAPA_2,
  LIMITAR_CAMBIOS,
  BLOQUEAR_CAMBIOS,
} from '../../config.json';

const INITIAL_OPTIONS = [
  { key: 'all', text: 'Todos los jugadores' },
  { key: 'activo', text: 'Activos' },
  { key: 'inactivo', text: 'Inactivos' },
];
class Players extends Component {
  constructor(props) {
    super(props);
    const {
      team: { cambios = 0 },
    } = props;
    this.selection = new Selection({
      onSelectionChanged: this.onSelectionChanged,
    });
    this.columns = jugadoresForm(this);
    this.state = {
      selectionCount: null,
      showModal: false,
      showModalTeam: false,
      items: [],
      currentUser: { ...modelUser },
      currentError: {},
      loading: false,
      puedeActivar: true,
      itemsLoaded: false,
      filtro: 'all',
      puedeCambiar: false,
      cambios,
    };
  }

  componentDidMount = () => {
    this.traerJugadores();
  };

  componentDidUpdate = prevProps => {
    const { team } = this.props;
    if (JSON.stringify(prevProps.team) !== JSON.stringify(team)) {
      this.traerJugadores();
    }
  };

  traerJugadores = async () => {
    const { team } = this.props;
    const jugadores = await traerJugadores(team);
    const puedeCambiar = this.puedeCambiar();
    this.setState({
      items: jugadores,
      itemsLoaded: true,
      loading: false,
      puedeCambiar,
    });
  };

  puedeCambiar = () => {
    const { profile } = this.props;
    const { cambios } = this.state;
    const puedeCambiar = !LIMITAR_CAMBIOS || cambios < CAMBIOS_ETAPA_2;
    const bloqueoGeneral =
      (BLOQUEAR_CAMBIOS && !profile.isAdmin) || profile.isViewer;

    return (profile.isAdmin || puedeCambiar) && !bloqueoGeneral;
  };

  activar = (activar = true) => {
    this.setState({
      items: this.itemsActivos(activar, true),
    });
    this.selection.setAllSelected(false);
  };

  itemsActivos = (activar, cambiar = false) => {
    const { team } = this.props;
    const { items } = this.state;
    return items.map(item => {
      if (this.selection.isKeySelected(item.identification)) {
        const jugador = {
          ...item,
          activo: item.capitan || activar,
        };
        if (cambiar) {
          editarJugador(jugador, team);
        }
        return jugador;
      }
      return item;
    });
  };

  puedeActivar = () => {
    const all = this.selection.isAllSelected();
    const jugadores = this.getActivos();
    if (all) {
      return jugadores.inscritos <= LIMITE_JUGADORES;
    }
    const nuevosActivos = this.itemsActivos(true).filter(item => item.activo);
    return nuevosActivos.length <= LIMITE_JUGADORES;
  };

  onSelectionChanged = () => {
    const count = this.selection.getSelectedCount();
    this.setState({
      selectionCount: count > 0 && count,
      puedeActivar: this.puedeActivar(),
    });
  };

  onItemInvoked = value => {
    this.selection.setAllSelected(false);
    const { items } = this.state;
    const currentUser = items.find(
      item => item.identification === value.identification,
    );
    this.setState({
      currentUser: {
        ...modelUser,
        ...currentUser,
        oldId: currentUser.identification,
        birthday: new Date(currentUser.birthday),
        nuevo: false,
      },
      currentError: {},
      showModal: true,
    });
  };

  onChange = event => {
    const { currentUser } = this.state;
    const { value, id } = event.target;
    currentUser[id] = value;
    this.setState({ currentUser });
  };

  guardarCurrent = async () => {
    const { team } = this.props;
    const { currentUser, items, cambios } = this.state;

    const idToCompare = currentUser.oldId
      ? currentUser.oldId
      : currentUser.identification;

    const currentError = await validarUsuario(
      currentUser,
      currentUser.nuevo,
      items
        .filter(item => item.identification !== idToCompare)
        .map(item => item.shirt),
      team.tid,
    );
    if (Object.keys(currentError).length > 0) {
      this.setState({ currentError });
      return;
    }
    this.setState({ showModal: false, loading: true });
    await editarJugador(currentUser, team);
    if (currentUser.nuevo) {
      await hacerCambio(team, cambios);
      const newCambios = cambios + 1;
      this.setState({ cambios: newCambios }, () => {
        const puedeCambiar = this.puedeCambiar();
        this.setState({ puedeCambiar });
      });
    }
    this.traerJugadores();
  };

  renderModal = () => {
    const {
      showModal,
      currentUser,
      currentError,
      items,
      puedeCambiar,
    } = this.state;
    const {
      profile: { isAdmin },
    } = this.props;

    const capitan = items.find(item => item.capitan);

    return (
      <Modal isOpen={showModal} onDismiss={this.closeModal}>
        <div className="modalContent">
          <Text variant="xLarge">
            {currentUser.nuevo ? 'Agregar Jugador' : 'Editar Jugador'}
          </Text>
          <Stack between className="formModal">
            <div>
              <TextField
                label="Cédula/TI"
                required
                id="identification"
                value={currentUser.identification}
                errorMessage={currentError.identification}
                disabled={!currentUser.nuevo && !isAdmin}
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: validator.toDigits(value.target.value),
                      id: value.target.id,
                    },
                  });
                }}
                type="number"
              />
              <TextField
                label="Primer y segundo nombre"
                required
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: value.target.value.toUpperCase(),
                      id: value.target.id,
                    },
                  });
                }}
                id="name"
                value={currentUser.name.toUpperCase()}
                errorMessage={currentError.name}
              />
              <TextField
                label="Primer y segundo apellido"
                required
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: value.target.value.toUpperCase(),
                      id: value.target.id,
                    },
                  });
                }}
                id="lastName"
                value={currentUser.lastName.toUpperCase()}
                errorMessage={currentError.lastName}
              />
              <MaskedTextField
                label="Celular"
                required
                mask="(999) 999-9999"
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: validator.toDigits(value.target.value),
                      id: value.target.id,
                    },
                  });
                }}
                id="tel"
                type="tel"
                value={currentUser.tel}
                errorMessage={currentError.tel}
              />
            </div>
            <div>
              <TextField
                label="e-mail"
                required
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: value.target.value.toLowerCase(),
                      id: value.target.id,
                    },
                  });
                }}
                id="email"
                value={currentUser.email.toLowerCase()}
                errorMessage={currentError.email}
                type="email"
              />
              <TextField
                label="# Camisa"
                id="shirt"
                value={currentUser.shirt}
                errorMessage={currentError.shirt}
                type="number"
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: validator.toDigits(value.target.value),
                      id: value.target.id,
                    },
                  });
                }}
              />
              <DatePicker
                label="Fecha de nacimiento"
                isRequired
                firstDayOfWeek={DayOfWeek.Sunday}
                ariaLabel="Selecciona una fecha"
                maxDate={subYears(new Date(), 4)}
                onSelectDate={value =>
                  this.onChange({ target: { value, id: 'birthday' } })
                }
                id="birthday"
                value={
                  typeof currentUser.birthday === 'string'
                    ? new Date(currentUser.birthday)
                    : currentUser.birthday
                }
                errorMessage={currentError.birthday}
              />
              <br />
              {isAdmin && (
                <Checkbox
                  label="Capitan"
                  isRequired
                  onChange={value =>
                    this.onChange({
                      target: { value: value.target.checked, id: 'capitan' },
                    })
                  }
                  boxSide="end"
                  checked={currentUser.capitan || false}
                  errorMessage={currentError.capitan}
                  disabled={
                    capitan &&
                    capitan.identification !== currentUser.identification
                  }
                />
              )}
            </div>
          </Stack>

          <DialogFooter>
            <PrimaryButton
              disabled={!puedeCambiar}
              onClick={this.guardarCurrent}
              text={currentUser.nuevo ? 'Guardar' : 'Modificar'}
            />
            <DefaultButton onClick={this.closeModal} text="Cancelar" />
          </DialogFooter>
        </div>
      </Modal>
    );
  };

  renderModalTeam = () => {
    const { showModalTeam } = this.state;
    const { team } = this.props;
    return (
      <TeamForm
        showModal={showModalTeam}
        onHideModal={this.closeModalTeam}
        team={team}
      />
    );
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  agregarJugador = () => {
    this.setState({
      currentUser: { ...modelUser, nuevo: true },
      currentError: {},
      showModal: true,
    });
  };

  closeModalTeam = () => {
    this.setState({ showModalTeam: false });
  };

  cambiarNombreEquipo = () => {
    this.setState({ showModalTeam: true });
  };

  getActivos = () => {
    const { items } = this.state;
    if (!items) return {};

    const inactivos = items.filter(item => !item.activo).length;
    return {
      inscritos: items.length,
      inactivos,
      activos: items.length - inactivos,
    };
  };

  eliminarEquipo = () => {
    this.setState({ showEliminarEquipo: true });
  };

  eliminarEquipoConfirmado = async () => {
    const { team } = this.props;
    await deleteTeam(team.tid);
    this.setState({ showEliminarEquipo: false });
    window.location.reload();
  };

  renderEliminarEquipo = () => {
    const { showEliminarEquipo } = this.state;
    return (
      <Dialog
        hidden={!showEliminarEquipo}
        onDismiss={() => {
          this.setState({ showEliminarEquipo: false });
        }}>
        <div className="modalContent">
          <Stack middle between>
            <Text>
              Estás a punto de eliminar un equipo, estos datos no podrán ser
              recuperados ¿Estás seguro de eliminar definitivamente este equipo?
            </Text>
          </Stack>

          <DialogFooter>
            <PrimaryButton
              onClick={this.eliminarEquipoConfirmado}
              text="Eliminar"
            />
            <DefaultButton
              onClick={() => {
                this.setState({ showEliminarEquipo: false });
              }}
              text="Cancelar"
            />
          </DialogFooter>
        </div>
      </Dialog>
    );
  };

  renderCommandHeader = () => {
    const { profile, team } = this.props;
    const { itemsLoaded, puedeCambiar } = this.state;
    const jugadores = this.getActivos();

    return (
      <CommandHeader title="Acciones Principales">
        {profile.isAdmin ? (
          <DefaultButton onClick={this.cambiarNombreEquipo}>
            Editar nombre del equipo
          </DefaultButton>
        ) : (
          <Text>{team.name}</Text>
        )}
        <div>
          {puedeCambiar && (
            <PrimaryButton
              onClick={this.agregarJugador}
              disabled={!itemsLoaded || jugadores.activos >= LIMITE_JUGADORES}>
              Agregar Jugador
            </PrimaryButton>
          )}
          &nbsp;
          {profile.isAdmin && (
            <DefaultButton onClick={this.eliminarEquipo}>
              Eliminar Equipo
            </DefaultButton>
          )}
          {LIMITAR_CAMBIOS && (
            <>
              <br />
              <p className="updateMessage">
                Recuerda que el botón de&nbsp;
                <b>
                  <i>“Agregar jugador”</i>
                </b>
                &nbsp; estará deshabilitado para realizar cambios en los horarios según el reglamento y socializados en el congreso técnico, Si requieres cambios por síntomas de COVID repórtalos a soporte@torneosoccercup.com Ganemos juntos este tiempo de juego!
              </p>
            </>
          )}
        </div>
      </CommandHeader>
    );
  };

  renderContent = () => {
    const { items, loading, itemsLoaded } = this.state;
    return (
      <>
        <div className="teams">
          {this.renderTopContent()}
          {itemsLoaded && items.length === 0 ? (
            <div className="dialogBox">
              <Text variant="xxLarge">Lista sin jugadores</Text>
            </div>
          ) : (
            this.renderTable()
          )}
        </div>
        {loading && <ProgressIndicator />}
      </>
    );
  };

  renderFilter = () => {
    const { profile, team } = this.props;

    const { items, filtro } = this.state;
    const itemsFiltered =
      filtro === 'all'
        ? items
        : items.filter(item => {
            if (item.activo && filtro === 'activo') {
              return true;
            }
            if (!item.activo && filtro === 'inactivo') {
              return true;
            }
            return false;
          });

    const keys = Object.keys(modelUser);

    const csvData = itemsFiltered.reduce(
      (chain, value) => {
        const values = keys.map(key => {
          if (key === 'updatedOn') {
            return value[key] ? formatDate(value[key]) : '';
          }

          return key === 'birthday' ? formatShortDate(value[key]) : value[key];
        });
        return [...chain, values];
      },
      [keys],
    );
    return (
      profile.isAdmin && (
        <>
          <Stack between>
            <div />
            <Stack middle end>
              <ComboBox
                defaultSelectedKey="all"
                label="Filtrar por:"
                options={INITIAL_OPTIONS}
                onChange={(e, { key }) => this.setState({ filtro: key })}
              />
              <span>&nbsp;&nbsp;</span>
              <CSVLink data={csvData} filename={`${team.name}-soccer-cup.csv`}>
                <PrimaryButton>Descargar listado</PrimaryButton>
              </CSVLink>
            </Stack>
          </Stack>
          <br />
        </>
      )
    );
  };

  renderTopContent = () => {
    const {
      selectionCount,
      puedeActivar,
      items,
      itemsLoaded,
      puedeCambiar,
      cambios,
    } = this.state;
    const jugadores = this.getActivos();
    const textoCambios = LIMITAR_CAMBIOS
      ? ` Llevas ${cambios}/${CAMBIOS_ETAPA_2} de los cambios permitidos`
      : '';

    const jugadoresActivos = jugadores.inscritos - jugadores.inactivos;

    return (
      <div className="top">
        <h4>Listado de Jugadores</h4>
        <Shimmer isDataLoaded={itemsLoaded}>
          {items.length > 0 && (
            <>
              {this.renderFilter()}
              <Stack between middle className="teamsTopBar">
                <Text>
                  {jugadores.inscritos} jugadores inscritos:&nbsp;
                  {jugadoresActivos} activo(s) -&nbsp;
                  {jugadores.inactivos} inactivo(s).{textoCambios}
                </Text>
                <Stack middle>
                  {puedeCambiar ? (
                    <>
                      <Text>Acciones&nbsp;&nbsp;</Text>
                      <PrimaryButton
                        onClick={() => this.activar()}
                        disabled={!puedeActivar}>
                        Activar {selectionCount}
                      </PrimaryButton>
                      &nbsp;
                      <DefaultButton onClick={() => this.activar(false)}>
                        Desactivar {selectionCount}
                      </DefaultButton>
                    </>
                  ) : null}
                </Stack>
              </Stack>
            </>
          )}
        </Shimmer>
      </div>
    );
  };

  renderTable = () => {
    const { items, itemsLoaded, filtro } = this.state;
    const itemsFiltered =
      filtro === 'all'
        ? items
        : items.filter(item => {
            if (item.activo && filtro === 'activo') {
              return true;
            }
            if (!item.activo && filtro === 'inactivo') {
              return true;
            }
            return false;
          });
    return (
      <MarqueeSelection selection={this.selection}>
        <ShimmeredDetailsList
          items={itemsFiltered.map(item => ({
            ...item,
            key: item.identification,
            estado: item.activo ? (
              <span className="inscrito">Activo</span>
            ) : (
              <span className="noInscrito">Inactivo</span>
            ),
          }))}
          columns={this.columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selection={this.selection}
          selectionPreservedOnEmptyClick
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          onItemInvoked={this.onItemInvoked}
          enableShimmer={!itemsLoaded}
        />
      </MarqueeSelection>
    );
  };

  render() {
    return (
      <>
        {this.renderCommandHeader()}
        {this.renderContent()}
        {this.renderModal()}
        {this.renderModalTeam()}
        {this.renderEliminarEquipo()}
      </>
    );
  }
}

Players.propTypes = {
  team: PropTypes.shape({ name: PropTypes.string }).isRequired,
  profile: profilePropType.isRequired,
};

Players.defaultProps = {};

export default profileOnly(Players);
