import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DefaultButton, Text } from 'office-ui-fabric-react/lib';
import { PropTypes } from 'prop-types';
import Logo from '../Logo';
import Stack from '../Stack';
import { profileOnly } from '../../utils/enhacers';
import { profilePropType } from '../../utils/proptypes';
import { TITULO_TORNEO } from '../../config.json';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  signOut = () => {
    const { firebase } = this.props;
    firebase.logout();
  };

  render() {
    const { profile } = this.props;

    return (
      <div className="header">
        <div className="logo">
          <Link to="/">
            <Stack middle>
              <Logo />
              <Text variant="xLarge">{TITULO_TORNEO}</Text>
            </Stack>
          </Link>
        </div>
        {profile.idLogged && (
          <div className="user">
            <Text>{profile.email}</Text>
            <br />
            <br />
            <Link to="/">
              <DefaultButton onClick={this.signOut}>
                Cerrar sesión
              </DefaultButton>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  firebase: PropTypes.shape({}).isRequired,
  profile: profilePropType.isRequired,
};

export default profileOnly(Header);
