import React from 'react';

const View404 = () => {
  return (
    <div>
      <h5>Página no encontrada</h5>
    </div>
  );
};

export default View404;
