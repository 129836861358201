import React from 'react';
import { PropTypes } from 'prop-types';

const SideBar = ({ children }) => {
  return <div className="sidebar">{children}</div>;
};

SideBar.Section = ({ children, title }) => {
  return (
    <div className="section">
      <h4>{title}</h4>
      {children}
    </div>
  );
};

SideBar.Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

SideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SideBar;
