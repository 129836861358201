import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import {
  Text,
  TextField,
  PrimaryButton,
  MessageBar,
  Spinner,
  MessageBarType,
} from 'office-ui-fabric-react/lib';
import { PropTypes } from 'prop-types';
import Header from '../../components/Header';
import Stack from '../../components/Stack';

class RemindPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      currentError: {},
    };
  }

  onChange = event => {
    const { currentUser } = this.state;
    const { value, id } = event.target;
    currentUser[id] = value;
    this.setState({ currentUser });
  };

  recordar = () => {
    const { currentUser } = this.state;

    if (!currentUser.email) {
      this.setState({ error: 'e-mail inválido' });
      return;
    }

    this.setState({ loading: true });
    this.recordarPassword();
  };

  recordarPassword = async () => {
    const {
      currentUser: { email },
    } = this.state;
    const { firebase } = this.props;
    try {
      await firebase.resetPassword(email);
      this.setState({
        error: '',
        message:
          'En unos minutos recibirás instrucciones para reestablecer tu contraseña en tu e-mail',
        loading: false,
      });
    } catch (error) {
      const { message } = error;
      this.setState({ error: message, loading: false });
    }
  };

  navigate = path => {
    const { history } = this.props;
    history.push(path);
  };

  render() {
    const { currentUser, currentError, error, loading, message } = this.state;
    return (
      <>
        <Header />
        <Stack column className="formContainer">
          <Text variant="xLarge">Recordar contraseña</Text>
          {(error || message) && (
            <MessageBar
              messageBarType={
                error ? MessageBarType.error : MessageBarType.success
              }
              onDismiss={this.cleanError}>
              {error || message}
            </MessageBar>
          )}
          <br />
          <TextField
            label="e-mail"
            required
            onChange={this.onChange}
            id="email"
            value={currentUser.email}
            errorMessage={currentError.email}
            type="email"
          />
          <br />
          <br />
          <PrimaryButton onClick={this.recordar} disabled={loading}>
            Recordar contraseña
          </PrimaryButton>
          <br />
          {loading && <Spinner />}
          <br />
        </Stack>
      </>
    );
  }
}

RemindPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  firebase: PropTypes.shape({}).isRequired,
};

export default withFirebase(RemindPassword);
