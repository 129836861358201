import Validator from 'fastest-validator';
import { isArray } from 'util';

const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const password = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?!.*[ ]).{8,}/;
const name = /^[A-z\u00C0-\u00ff]+(?:(\s|,\s|\s-\s)[A-z\u00C0-\u00ff]+)*$/;

export const patterns = { email, password, name };

const validatorDefault = {
  isName: str => name.test(str),
  isEmail: str => email.test(str),
  isPassword: str => password.test(str),
  toDigits: str => str.replace(/\D/g, ''),
};

export const validateForm = (item, validationSchema) => {
  const validator = new Validator({
    messages: {
      stringEmpty: 'Ingresa un valor!',
      stringNumeric: 'Ingresa un número válido!',
      stringPattern: 'Ingresa un valor válido!',
      email: 'Ingresa un e-mail válido!',
      date: 'Selecciona un fecha válida!',
    },
  });

  const validatorResponse = validator.validate(item, validationSchema);
  const errors = isArray(validatorResponse) ? validatorResponse : [];
  return errors.reduce((chain, current) => {
    return { ...chain, [current.field]: current.message };
  }, {});
};

export default validatorDefault;
