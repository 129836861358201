import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, DefaultButton } from 'office-ui-fabric-react/lib';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import Stack from '../../components/Stack';
import Content from '../../components/Content';
import TeamsList from '../../components/TeamsList';
import CommandHeader from '../../components/CommandHeader';
import TeamForm from '../../components/TeamForm';
import PlayersList from '../../components/PlayersList';
import { profileOnly } from '../../utils/enhacers';
import { profilePropType } from '../../utils/proptypes';
import DownloadAll from '../../components/DownloadAll';
import { NOMBRE_TORNEO } from '../../config.json';

class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
      showModalTeam: false,
    };
  }

  onTeamSelected = team => {
    this.setState({ team });
  };

  agregarEquipo = () => {
    this.setState({ showModalTeam: true });
  };

  hideAgregarEquipo = () => {
    this.setState({ showModalTeam: false });
  };

  renderSelectedContent = () => {
    const { team } = this.state;
    return <PlayersList team={team} key={team.tid} />;
  };

  renderAddTeam = () => {
    const { profile } = this.props;
    return (
      profile.isAdmin && (
        <CommandHeader title="Acciones Principales">
          <DefaultButton onClick={this.agregarEquipo}>
            Agrega un Equipo
          </DefaultButton>
          <DownloadAll />
        </CommandHeader>
      )
    );
  };

  renderModalTeam = () => {
    const { showModalTeam } = this.state;
    return (
      <TeamForm
        showModal={showModalTeam}
        onHideModal={this.hideAgregarEquipo}
      />
    );
  };

  render() {
    const { team } = this.state;
    const { match } = this.props;
    return (
      <>
        <Header />
        <Stack className="fullSize">
          <SideBar>
            <SideBar.Section title="Torneo">
              <Text>{NOMBRE_TORNEO}</Text>
            </SideBar.Section>
            <SideBar.Section title="Equipos">
              <TeamsList onTeamSelected={this.onTeamSelected} match={match} />
            </SideBar.Section>
          </SideBar>
          <Content>
            {team ? this.renderSelectedContent() : this.renderAddTeam()}
          </Content>
        </Stack>
        {this.renderModalTeam()}
      </>
    );
  }
}

Teams.propTypes = {
  profile: profilePropType.isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default profileOnly(Teams);
